<template>
	<div>
		<ContentHeader title="Kasir" />
		 <div class="content-header">
			<div class="container-fluid">
	            <div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div>Pasien Proses Pembayaran</div></h3>

	                <div class="card-tools">
	                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
	                    <i class="fas fa-minus"></i>
	                  </button>
	                </div>
	              </div>
	              <div class="card-body" style="display: block;">
	              	<table class="table table-bordered table-hover">
	              		<thead>
							<tr>
								<th scope="col">No</th>
								<th scope="col">Nomor Antrian</th>
								<th scope="col">NAMA</th>
								<th scope="col">NOMOR TELEPON</th>
								<th scope="col">STATUS</th>
								<th scope="col">POLI</th>
								<th scope="col">LAYANAN</th>
								<th scope="col">WAKTU KUNJUNGAN</th>
								<th scope="col">ACTION</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in pasienPembayaran" :key="row.id">
								<td scope="row">{{ increment(index) }}</td>
								<td>{{ row.nomor }}</td>
								<td>{{ row.nama_pasien }}</td>
								<td>{{ row.nomor_telepon }}</td>
								<td>{{ row.status }}</td>
								<td>{{ row.poli?.nama }}</td>
								<td>{{ row.layanan?.nama }}</td>
								<td class="text-center">{{ dayjs(row.created_at).format('HH:mm') }}</td>
								<td>
								<div class="btn-group">
									<router-link :to="`/tambah-data-pembayaran/${row.id}`" class="btn btn-sm btn-info">Proses</router-link>		
									</div>
								</td>
							</tr>
						</tbody>
	              	</table>
	              	<p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
	              	<p class="text-center mt-2" v-if="cek_data">Data Masih Kosong</p>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
	            </div>

			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader'

	import axios from 'axios'
	import { ref, onMounted, computed } from 'vue'
	import store from '@/store'
	import dayjs from 'dayjs'

	const increment = (index) => {
		return index+1
	}

	const user = computed(() => store.getters['auth/user'])
	const pasienPembayaran = ref()
	const memuat_data = ref(true)
	const cek_data = ref(false)
	const getPasienPembayaran = async () => {
		let response = await axios.get('api/kunjungan/getWherePembayaran/' + user.value.cabang_id)
		
		if (response.data == 'kosong') {
			memuat_data.value = false
			cek_data.value = true
			pasienPembayaran.value = null
		} else {
			memuat_data.value = false
			cek_data.value = false
			pasienPembayaran.value = response.data
		}	
	}

	// Refresh data 
	setInterval(() => {
		getPasienPembayaran()		
	}, 240000)
	// End Refresh Data

	onMounted(() => {
		getPasienPembayaran()
	})
</script>